import React, { useState } from "react";
import { Plugin, Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import domtoimage from "dom-to-image";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { withStyles } from "@material-ui/core/styles";

import exportToImage from '../../helpers/export/exportToImage';
import exportToPdf from '../../helpers/export/exportToPdf';
import print from '../../helpers/export/print'

const rootContainerId = "iab-chart-wrapper";
const iconButton = "exportIconButton";

const exportToJpeg = chart => exportToImage(chart, "jpeg", domtoimage.toJpeg);

const exportToPng = chart => exportToImage(chart, "png", domtoimage.toPng);

const options = [
  { key: "Print", action: print, text: "Print" },
  { key: "JPEG", action: exportToJpeg, text: "Save as JPEG" },
  { key: "PNG", action: exportToPng, text: "Save as PNG" },
  { key: "PDF", action: exportToPdf, text: "Save as PDF" }
];

const styles = {
  button: {
    width: "50px",
    height: "50px"
  }
};

const ITEM_HEIGHT = 48;
const paperProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: 150
  }
};

const ExportBase = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = ({ action }) => () => {
    const chart = document.querySelector(`#${rootContainerId}`);
    handleClose();
    action(chart);
  };

  const open = Boolean(anchorEl);

  const { classes } = props;
  return (
    <Plugin name="Export">
      <Template name="top">
        <TemplatePlaceholder />
        <div className="iab-export">
          <span>Download Graph</span>
          <IconButton
            id={iconButton}
            onClick={handleClick}
            className={classes.button}
          >
            <ArrowDropUp />
          </IconButton>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={paperProps}
        >
          {options.map(option => (
            <MenuItem key={option.key} onClick={handleExport(option)}>
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </Template>
    </Plugin>
  );
};

const Export = withStyles(styles)(ExportBase);

export default Export;