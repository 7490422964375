import React  from 'react';

const GraphLoading = props => {
  return (
    <section className="iab-general">
      <div className="iab-general__loading">
      </div>
    </section>
  )
}

export default GraphLoading;