import getSeriesKeyValue from './getSeriesKeyValue';
import getCategoryData from './getCategoryData';
import getFilterData from './getFilterData';
import getSearchedObjectData from './getSearchedObjectData';

const createGraphStacks = ( yearAPIData, year, searchKeyObject, primaryFilters, secondaryFilters, showTotal ) => {

  // stacks={[
  //   { series: ['Male: 0-14', 'Male: 15-64', 'Male: 65 and older', 'Female: 0-14', 'Female: 15-64', 'Female: 65 and older'] },
  //   { series: ['Female: 0-14', 'Female: 15-64', 'Female: 65 and older'] },
  // ]}

  let primaryStacks = [];
  let secondaryStacks = []
  let series = {};
  //let tempTotal = 0; // used to calculate remaining total

  const {key: searchedObjectKey, value: searchedObjectTotalValue} = getSeriesKeyValue(searchKeyObject)
  const { filters: pFilters, filterName: pName } = primaryFilters;
  const { filters: sFilters } = secondaryFilters;


  // If primary filters are categories we need to look at API data for the category
  if ( pName === 'Categories' ) {

    let catTotal = 0
    // create normal bars by looking for category objects
    const { series: categorySeries, tempTotal: categoryTempTotal } = getCategoryData( yearAPIData, year, pFilters );
    series = categorySeries;
    // catTotal = categoryTempTotal;

    if (categorySeries && categoryTempTotal) {
      for (let [filterSeriesKey, filterSeriesValue] of Object.entries(categorySeries)) {
        // add matched category to series
        series[filterSeriesKey] = filterSeriesValue;
        primaryStacks.push(filterSeriesKey)
        // add to total so we can remove it later
        catTotal += filterSeriesValue;
      }

      if (catTotal < searchedObjectTotalValue && showTotal) {
        const remainingTotal = searchedObjectTotalValue - catTotal;
        const totalName = `${searchedObjectKey} Total`;
        series[totalName] = remainingTotal;
        primaryStacks.push(totalName)
      }
    }



    // create second stack with bars for each primary filter cross referenced with secondary filter
    pFilters.forEach(pFilter => {

      // create search category with parent hypened category e.g. 'market_data' and 'display' becomes 'market_data-display'
      const categoryData = getSearchedObjectData(yearAPIData, year, pFilter);
      if (categoryData) {
        const categoryName = categoryData.sub_category
        const categoryTotal = categoryData.total
        let filterTotal = 0
        const { series: filterSeries, tempTotal: filterTempTotal } = getFilterData( categoryData, sFilters );
        if (filterSeries && filterTempTotal) {
          for (let [filterSeriesKey, filterSeriesValue] of Object.entries(filterSeries)) {
            // add matched category to series
            series[filterSeriesKey] = filterSeriesValue;
            secondaryStacks.push(filterSeriesKey)
            // add to total so we can remove it later
            filterTotal += filterSeriesValue;
          }

          if (filterTotal < categoryTotal && showTotal) {
            const remainingTotal = categoryTotal - filterTotal;
            const totalName = `${categoryName} Total`;
            series[totalName] = remainingTotal;
            secondaryStacks.push(totalName)
          }
        }
      }
    })
    return {series, primaryStacks, secondaryStacks};

  // If primary filters are environment or device we need to look at filter list for the object
  } else {
    const categoryName = searchKeyObject.sub_category
    const categoryTotal = searchKeyObject.total;

    const { series: filterPrimarySeries, tempTotal: filterPrimaryTempTotal } = getFilterData( searchKeyObject, pFilters );

    if (filterPrimarySeries && filterPrimaryTempTotal) {
      let filterATotal = 0;
      for (let [filterSeriesKey, filterSeriesValue] of Object.entries(filterPrimarySeries)) {
        // add matched category to series
        series[filterSeriesKey] = filterSeriesValue;
        primaryStacks.push(filterSeriesKey)
        // add to total so we can remove it later
        filterATotal += filterSeriesValue;
      }

      if ((filterATotal < categoryTotal) && showTotal) {
        const remainingTotal = categoryTotal - filterATotal;
        const totalName = `${categoryName} Total`;
        series[totalName] = remainingTotal;
        primaryStacks.push(totalName)
      }
    }

    const { series: filterSecondarySeries, tempTotal: filterSecondaryTempTotal, secondaryFilterName } = getFilterData( searchKeyObject, pFilters, sFilters );

    if (filterSecondarySeries && filterSecondaryTempTotal && filterPrimaryTempTotal) {
      let filterBTotal = 0;
      for (let [filterSeriesKey, filterSeriesValue] of Object.entries(filterSecondarySeries)) {
        // add matched category to series
        series[filterSeriesKey] = filterSeriesValue;
        secondaryStacks.push(filterSeriesKey)
        // add to total so we can remove it later
        filterBTotal += filterSeriesValue;
      }

      if ((filterBTotal < filterPrimaryTempTotal) && showTotal) {
        const remainingTotal = filterPrimaryTempTotal - filterBTotal;
        const totalName = `${categoryName}, ${secondaryFilterName}, Total`;
        series[totalName] = remainingTotal;
        secondaryStacks.push(totalName)
      }
    }
  }
  return {series, primaryStacks, secondaryStacks};
}

export default createGraphStacks;