import getSeriesKeyValue from './getSeriesKeyValue';
// import getSearchedObjectData from './getSearchedObjectData';
// import checkFilterForValues from '../filters/checkFilterForValues';
import getCategoryData from './getCategoryData';
import getFilterData from './getFilterData';

const createVerticalBars = (pName, yearAPIData, year, searchKeyObject, pFilters, showTotal) => {
  let series = {};
  let tempTotal = 0; // used to calculate remaining total

  const {key: searchKeyObjectName, value: searchKeyObjectValue} = getSeriesKeyValue(searchKeyObject)

  // If primary filters are categories we need to look at API data for the category
  if ( pName === 'Categories' ) {

    const { series: categorySeries, tempTotal: categoryTempTotal } = getCategoryData( yearAPIData, year, pFilters );
    series = categorySeries;
    tempTotal = categoryTempTotal;

  // If primary filters are environment or device we need to look at filter list for the object
  } else {

    const { series: filterSeries, tempTotal: filterTempTotal } = getFilterData( searchKeyObject, pFilters );
    series = filterSeries;
    tempTotal = filterTempTotal;

  }

  if (tempTotal < searchKeyObjectValue && showTotal) {
    const remainingTotal = searchKeyObjectValue - tempTotal
    series[`${searchKeyObjectName} Total`] = remainingTotal;
  }

  return series;
}

export default createVerticalBars;