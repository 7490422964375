import translateFilterKeyToTitle from './translateFilterKeyToTitle';

const translateEnvironmentTitlesFromKey = searchName => {
  switch(searchName) {
    case 'digital_market---classified':
      return [
        {
          title: translateFilterKeyToTitle[searchName]['environmentA']
        }, {
          title: translateFilterKeyToTitle[searchName]['environmentB']
        }
      ];
    default:
      return [
        {
          title: translateFilterKeyToTitle['default_values']['environmentA']
        }, {
          title: translateFilterKeyToTitle['default_values']['environmentB']
        }
      ];
  }
}

export default translateEnvironmentTitlesFromKey;