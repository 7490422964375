import React from "react";
import Year from "./year";
const today = new Date().getFullYear();

function YearsList({ selectedYear, selectedYears, chooseYear, heldSelectedYear, heldSelectedYearTo }) {
  const startYear = heldSelectedYear === 0 ? today - 4 : parseInt(heldSelectedYear - 4);
  const yearsArray = Array.from(
    new Array(9),
    (val, index) => index + startYear
  );

  return yearsArray.map(item => (
    <Year
      key={item}
      chooseYear={chooseYear}
      selectedYear={selectedYear}
      year={item}
      highlighted={selectedYears.includes(item)}
    />
  ));
}

export default YearsList;
