  import React from "react";

  function Button({ direction, onClick, title }) {
    let icon = direction === "forward" ? ">" : "<";
    let buttonClass = direction === "forward" ? "nav-button-next" : "nav-button-back";
    function handlerClick(e) {
      e.preventDefault();
      if (onClick) {
        onClick(title);
      }
    }

    return (
      <button className={`nav-button  ${buttonClass}`} onClick={handlerClick}>
        {icon}
      </button>
    );
  }

  export default Button;
