import getSearchedObjectData from './getSearchedObjectData';
import getSeriesKeyValue from './getSeriesKeyValue';
import createGraphBars from './createGraphBars';
import createGraphStacks from './createGraphStacks';

const createSingleYearSeries = (yearAPIData, year, searchKey, primaryFilters, secondaryFilters, showTotal) => {
  const searchKeyObject = getSearchedObjectData(yearAPIData, year, searchKey);

  let series = {};
  let stacks = [];
  let primaryStacks = [];
  let secondaryStacks = [];

  if (!searchKeyObject) {
    return series;
  }

  const {key: searchedObjectKey, value: searchedObjectTotalValue} = getSeriesKeyValue(searchKeyObject)
  const { filters: pFilters, filterName: pName } = primaryFilters;
  const { filters: sFilters } = secondaryFilters;

  if ( !pFilters.length && !sFilters.length ) {
    // create normal graph
    series[searchedObjectKey] = searchedObjectTotalValue;
  } else if ( pFilters.length && !sFilters.length) {
    // create stacked graph with bars made of primary filter
    series = createGraphBars( pName, yearAPIData, year, searchKeyObject, pFilters, showTotal );
  } else if ( pFilters.length && sFilters.length ) {
    // create stacked graph with bars made of primary filters and side stacks of secondary fiters
    const graphStacks = createGraphStacks( yearAPIData, year, searchKeyObject, primaryFilters, secondaryFilters, showTotal );
    series = graphStacks.series
    stacks = [graphStacks.primaryStacks,graphStacks.secondaryStacks]
    primaryStacks = graphStacks.primaryStacks
    secondaryStacks = graphStacks.secondaryStacks
  }

  series['date'] = year;

  return {series, stacks, primaryStacks, secondaryStacks};
}

export default createSingleYearSeries;