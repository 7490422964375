import React, { Component } from "react";
import YearInput from "./components/YearInput";
import PickerPanel from "./components/PickerPanel/index";

class YearPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearIsSelected: false,
      heldSelectedYear: 0,
      heldSelectedYearTo: 0,
      selectedYear: 0,
      selectedYearTo: 0,
      selectedYears: [],
      panelIsOpen: false,
      panelTop: 0,
      panelLeft: 0
    };
  }

  panelPosition = () => {
    const picker = document.querySelector(".iab-date-picker");
    const X = picker.getBoundingClientRect().left;
    const Y = picker.getBoundingClientRect().bottom;

    const elementHeight = picker.getBoundingClientRect().height;
    const elementWidth = picker.getBoundingClientRect().width;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const topTrue = Y - elementHeight - 10 > 220;
    const halfTopTrue = Y - elementHeight - 10 > 110;
    const botTrue = windowHeight - Y - 10 > 220;
    const halfBotTrue = windowHeight - Y - 10 > 110;
    const leftTrue = X + elementHeight / 2 > 120;
    const rightTrue = windowWidth - X - elementWidth / 2 > 120;

    if (topTrue && !botTrue && leftTrue && rightTrue) {
      const top = -230;
      const left = -120 + elementWidth / 2;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (!topTrue && botTrue && rightTrue && leftTrue) {
      const top = elementHeight + 10;
      const left = -120 + elementWidth / 2;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (halfBotTrue && halfTopTrue && leftTrue && !rightTrue) {
      const top = -110 + elementHeight / 2;
      const left = -250;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (halfBotTrue && halfTopTrue && !leftTrue && rightTrue) {
      const top = -110 + elementHeight / 2;
      const left = elementWidth + 10;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (!topTrue && botTrue && leftTrue && !rightTrue) {
      const top = 0;
      const left = -250;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (topTrue && !rightTrue && leftTrue && !botTrue) {
      const top = -220 + elementHeight;
      const left = -250;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (!topTrue && rightTrue && !leftTrue && botTrue) {
      const top = 0;
      const left = elementWidth + 10;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (topTrue && rightTrue && !leftTrue && !botTrue) {
      const top = -220 + elementHeight;
      const left = elementWidth + 10;
      this.setState({ panelTop: top, panelLeft: left });
    }
  };

  componentDidMount() {
    this.panelPosition();

    this.createYearArray(this.state.selectedYear, this.state.selectedYearTo);

    document.addEventListener(
      "scroll",
      function(event) {
        this.panelPosition();
      }.bind(this)
    );

    document.addEventListener(
      "click",
      function(event) {
        if (!event.target.closest(".iab-date-picker")) {
          this.closePanel();
        }
      }.bind(this)
    );

    this.props.setFromYear(this.state.selectedYear);
    this.props.setToYear(this.state.selectedYearTo);
    // if (this.state.selectedYearTo !== 0 && this.state.selectedYearTo !== 0 && this.state.yearsSelected === false) {
    //   this.setState({
    //     yearIsSelected: true,
    //   });
    //   this.props.yearsSelected(true);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedYear !== this.state.selectedYear || prevState.selectedYearTo !== this.state.selectedYearTo) {
      this.createYearArray(this.state.selectedYear, this.state.selectedYearTo)
    }

    if (this.state.yearIsSelected !== prevState.yearIsSelected && this.state.yearIsSelected === true) {
      this.props.yearsSelected(true);
    }
  }

  openPanel = event => {
    this.panelPosition();
    this.setState({
      panelIsOpen: true,
      yearIsSelected: false,
    });
  };

  closePanel = event => {
    this.setState({ panelIsOpen: false });
    this.setState({
      heldSelectedYear: this.state.selectedYear,
      heldSelectedYearTo: this.state.selectedYearTo,
      yearIsSelected: true
    })
  };

  chooseYear = year => {
    year = year.toString();
    this.setState({
      selectedYear: year,
    });
    this.props.setFromYear(year);
  };

  chooseYearTo = year => {
    this.setState({
      selectedYearTo: year,
    });
    this.props.setToYear(year);
    this.closePanel();
  };

  clearYear = () => {
    this.setState({
      selectedYear: new Date().getFullYear(),
      yearIsSelected: false
    });
  };

  increaseYear = title => {
    if (title === 'yearFrom') {
      this.setState({ selectedYear: this.state.selectedYear + 1 });
    } else {
      this.setState({ selectedYearTo: this.state.selectedYearTo + 1 });
    }

  };

  decreaseYear = title => {
    if (title === 'yearFrom') {
      this.setState({ selectedYear: this.state.selectedYear - 1 });
    } else {
      this.setState({ selectedYearTo: this.state.selectedYearTo - 1 });
    }
  };

  jumpForward = event => {
    this.setState({ selectedYear: this.state.selectedYear + 5 });
  };

  jumpBackward = event => {
    this.setState({ selectedYear: this.state.selectedYear - 5 });
  };

  createYearArray = (fromYear, toYear) => {
    if (fromYear !== 0 && toYear !== 0) {
      let arr = [];
      while(fromYear <= toYear){
         arr.push(fromYear++);
      }
      this.setState({
        selectedYears: arr,
      });
    }
  }

  render() {
    return (
      <div className={`iab-date-picker iab-item-filter ${this.state.selectedYear && this.state.selectedYearTo ? 'iab-date-picker--active' : ''} `}>
        <div className="iab-date-picker__title iab-item-filter__title">Date Range</div>
        <YearInput
          selectedYear={this.state.selectedYear}
          selectedYearTo={this.state.selectedYearTo}
          openPanel={this.openPanel}
          selected={this.state.yearIsSelected}
          clear={this.clearYear}
        />
        <PickerPanel
          isOpen={this.state.panelIsOpen}
          selectedYear={this.state.selectedYear}
          selectedYearTo={this.state.selectedYearTo}
          heldSelectedYear={this.state.heldSelectedYear}
          heldSelectedYearTo={this.state.heldSelectedYearTo}
          selectedYears={this.state.selectedYears}
          increaseYear={this.increaseYear}
          decreaseYear={this.decreaseYear}
          jumpForward={this.jumpForward}
          jumpBackward={this.jumpBackward}
          thisYear={this.thisYear}
          chooseYear={this.chooseYear}
          chooseYearTo={this.chooseYearTo}
          top={this.state.panelTop}
          left={this.state.panelLeft}
        />
      </div>
    );
  }
}

export default YearPicker;
