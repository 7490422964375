const getTotal = input => {
  let sum = 0;
  if (input.total || input.value) {
    sum = input.total || input.value;
  } else {
    console.log(input);
    // input.map(item => {
    //   if (item.value) {
    //     sum += item.value
    //   }
    //   return sum;
    // })
    // return sum;
  }
  return sum;
};

export default getTotal;