import React from 'react'
import Filter from './Filter';

const Filters = props => {
  const activeFilterUpdate = (title, array) => {
    switch(title) {
      case 'Categories':
        if (!array.length && props.secondaryFilters.filters.length) {
          // if you empty categories array and filters exist in secondary make them primary
          props.handleSetPrimaryFilters(props.secondaryFilters.filterName, props.secondaryFilters.filters);
          // clear secondary filters
          props.handleSetSecondaryFilters('', []);
        } else if (props.primaryFilters.filters.length && props.primaryFilters.filterName !== 'Categories'){
          // if other non category filters already exist in primary state them to secondary
          props.handleSetSecondaryFilters(props.primaryFilters.filterName, props.primaryFilters.filters);
          // then set primary filters
          props.handleSetPrimaryFilters(title, array);
        } else {
          // set primary filters with categories
          props.handleSetPrimaryFilters(title, array);
        }
        break;
      default:
        if (!props.primaryFilters.filters.length || props.primaryFilters.filterName === title){
          // if primary filters is empty or we are updating with current array series
          props.handleSetPrimaryFilters(title, array);
        } else {
          // update the second filter array
          props.handleSetSecondaryFilters(title, array);
        }
        return;
    }
  }

  const disableFilter = filterName => {
    const {primaryFilters, secondaryFilters} = props;
    if ( primaryFilters.filters.length && secondaryFilters.filters.length ) {
      const filterList = [primaryFilters.filterName, secondaryFilters.filterName];
      if (!filterList.includes(filterName)) {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <React.Fragment>
      {props.categoryListOptions ? <Filter content={props.categoryListOptions} disabled={disableFilter('Categories') || !props.chartData.length} title='Categories' key='key1' activeFilterUpdate={activeFilterUpdate} /> : null }
      {props.environmentListOptions ? <Filter content={props.environmentListOptions} disabled={disableFilter('Environment')  || !props.chartData.length} title='Environment' key='key2' activeFilterUpdate={activeFilterUpdate} /> : null }
      {props.deviceListOptions ? <Filter content={props.deviceListOptions} disabled={disableFilter('Device')  || !props.chartData.length} title='Device' key='key3' activeFilterUpdate={activeFilterUpdate} /> : null }
    </React.Fragment>
  )
}

export default Filters;