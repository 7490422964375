import { sidebarData } from "../../components/Sidebar/sidebarData";

const getSetActiveItem = (list, item) => {
  const data = sidebarData[list].categories[item];
  const { title, apiSearchTitle, categoryFilters } = data;
  const key = `${title}-${list}-${item}`;

  return {title, apiSearchTitle, categoryFilters, key};
}

export default getSetActiveItem;

export const selectedSideBarItem = getSetActiveItem(0,0);