import translateFilterKeyToTitle from './translateFilterKeyToTitle';

const translateDeviceTitlesFromKey = searchObject => {
  switch(searchObject) {
    default:
      return [
        {
          title: translateFilterKeyToTitle['digital_market']['deviceA']
        }, {
          title: translateFilterKeyToTitle['digital_market']['deviceB']
        }
      ];
  }
}

export default translateDeviceTitlesFromKey;