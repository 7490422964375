import getSearchedObjectData from './getSearchedObjectData';
import getSeriesKeyValue from './getSeriesKeyValue';

const getCategoryData = (yearAPIData, year, categoryFilters) => {
  let series = {};
  let tempTotal = 0;

  categoryFilters.forEach(searchKey => {

    // create search category with parent hypened category e.g. 'market_data' and 'display' becomes 'market_data-display'
    const categoryData = getSearchedObjectData(yearAPIData, year, searchKey);

    if (categoryData) {
      const {key: categoryKey, value: categoryValue} = getSeriesKeyValue(categoryData)
      // add matched category to series
      series[categoryKey] = categoryValue;
      // add to total so we can remove it later
      tempTotal += categoryValue
    }
    return {series, tempTotal }
  })

  return {series, tempTotal }
}

export default getCategoryData;