import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Accordion } from 'semantic-ui-react';
import { sidebarData } from "./sidebarData";
import { selectedSideBarItem } from "../../helpers/sidebar/getSetActiveItem";

const Sidebar = props => {
  const [currentActiveKey, setCurrentActiveKey] = useState(selectedSideBarItem.key)

  const handleClick = (content, key) => {
    const {title, apiSearchTitle, categoryFilters } = content;
    setCurrentActiveKey(key);
    props.handleUpdateTitle(title);
    props.handleUpdateApiSearchTitle(apiSearchTitle);
    props.handleUpdateCategories(categoryFilters || []);
  }

  const AccordionContent = (content, key) => (
    <div
      style={{
        padding: '0.5em 0',
        fontFamily: 'Lato',
        fontSize: '1em',
        color: 'rgba(0,0,0,.87)',
        margin: "0 0 0 10px",
      }}
      onClick={() => handleClick(content, key)}
      className={`indent iab-sidebar-selector__item ${currentActiveKey === key ? 'active' : ''}`}
    >
      {content.title}
    </div>
  )

  const createNestedAccordian = (item, indexKey) => {
    const categories = item.categories
    return (
      <Accordion.Accordion
        style={{margin: "0 0 0 20px"}}
        className="no-padding iab-sidebar-selector__panel"
        // exclusive={false}
        key={`${item.title}-${indexKey}`}
        panels={
          categories.map((category, i) => {
            let itemObject = {};
            const key = `${category.title}-${indexKey}-${i}`;
            if ( category.categories && category.categories.length ) {
              itemObject = {
                title: category.title,
                content: {
                  content: createNestedAccordian(category, indexKey),
                  key: key,
                }
              }
            } else {
              itemObject = {
                content: {
                  content: AccordionContent(category, key),
                  key: key,
                }
              }
            }
            return itemObject;
          })
        }
      />

    )
  }

  const createTopLevelAccordian = () => {
    return (
      sidebarData.map((item, i) => {
        const itemObject = {
          title: item.title,
          content: {
            content: createNestedAccordian(item, i),
            key: `${item.title}-${i}`,
          }
        }
        return itemObject;
      })
    )
  }

  return (
    <Accordion
      defaultActiveIndex={0}
      panels={createTopLevelAccordian()}
      className='iab-sidebar-selector'
    />
  )
}

export default Sidebar;
