import getTotal from './getTotal';

const getSeriesKeyValue = (yearData) => {
  const key = yearData.sub_category;
  const filters = yearData.filters;
  const value = getTotal(yearData);

  return {key, filters, value};
}

export default getSeriesKeyValue;