const getSearchedObjectData = (apiData, year, searchKey ) => {
  const searchBreakdown = searchKey.split('---');
  const categoryGroup = searchBreakdown[0];
  const categoryTitle = searchBreakdown[1];

  const searchItemData = apiData[year][categoryGroup].data[categoryTitle];
  if (!searchItemData) {
    return;
  }

  return searchItemData;
}

export default getSearchedObjectData;