import domtoimage from "dom-to-image";

const iconButton = "exportIconButton";
const filter = node => node.id !== iconButton;

const print = async chart => {
  try {
    const dataUrl = await domtoimage.toJpeg(chart, { filter });
    let html = "<html><head><title></title></head>";
    html += '<body style="width: 100%; padding: 0; margin: 0;"';
    html += ' onload="window.focus(); window.print(); window.close()">';
    html += `<img src="${dataUrl}" /></body></html>`;

    const printWindow = window.open("", "print");
    printWindow.document.open();
    printWindow.document.write(html);
    printWindow.document.close();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("oops, something went wrong!", err);
  }
};

export default print;
