import React, { useState, useEffect } from 'react'
import { Accordion, Form, Menu } from 'semantic-ui-react'
import usePrevious from '../../helpers/reactHooks/usePrevious';

const Filter = props => {
  const [activeIndex, setActiveIndex] = useState(props.index);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const {activeFilterUpdate, title} = props

  const prevFilters = usePrevious(selectedFilters);

  useEffect(() => {
    if ( prevFilters !== selectedFilters) {
      activeFilterUpdate(title, selectedFilters);
    }
  }, [selectedFilters, title, activeFilterUpdate, prevFilters]);

  const handleWrapperClick = (e, props) => {
    const index = props.index;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex)
  }

  const handleSelectAllClick = e => {
    if (arraysMatch(props.content, selectedFilters)) {
      setSelectedFilters(selectedFilters => []);
    } else {
      addItems(createSearchName(props.content))
    }
  }

  const handleIndividualClick = (e, props) => {
    if (!props.checked) {
      setSelectedFilters(selectedFilters.filter(item => item !== props.name));
    } else {
      addItems([props.name]);
    }
  }

  const addItems = items => {
    // could be more than one item
    items.forEach(item => {
      if (!selectedFilters.includes(item)) {
        setSelectedFilters(selectedFilters => [...selectedFilters, item]);
      }
    })
  }

  const arraysMatch = (array1, array2) => {
    const arraySO = createSearchName(array1);
    // Check if the array exists and are the same length
    if (arraySO.length !== array2.length) return false;

    const sortedArray1 = [...arraySO].sort();
    const sortedArray2 = [...array2].sort();

    // Check if all items exist and are in the same order
    for (var i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) return false;
    }

    // Otherwise, return true
    return true;
  };

  const createSearchName = array => {
    return array.map(item => {
      // console.log(item);
      if (props.title === "Categories") {
        return `${item.searchObject.group}---${item.searchObject.title}`
      } else {
        return item.title
      }
    })
  }

  const accordianContent = (
    <Form>
      <Form.Group grouped>
        {props.content.length ? (
          <Form.Checkbox
            label={`${arraysMatch(props.content, selectedFilters) ? 'Clear' : 'Show'} ${props.content.length === 2 ? 'both' : 'all'}`}
            name='all'
            value='all'
            key='all'
            checked={arraysMatch(props.content, selectedFilters)}
            className="iab-item-filter__toggle-all"
            onClick={handleSelectAllClick}
          />
        ) : null}
        {props.content.length ?  props.content.map((item, index) => {
          const relationshipKey = props.title === "Categories" ? `${item.searchObject.group}---${item.searchObject.title}` : item.title
          return (
            <Form.Checkbox
              label={item.title}
              name={relationshipKey}
              value={relationshipKey}
              key={relationshipKey}
              checked={selectedFilters.includes(relationshipKey)}
              onClick={handleIndividualClick}
            />
          )
        }) : null }
      </Form.Group>
    </Form>
  )

  return (
    <div className={`iab-item-filter ${selectedFilters.length ? 'iab-item-filter--active' : ''} ${!props.content.length || props.disabled ? 'iab-item-filter--inactive' : ''}`}>
      <Accordion as={Menu} vertical className="iab-item-filter__accordion">
        <Menu.Item>
          <div className="iab-item-filter__title">{props.title}</div>
          <Accordion.Title
            active={props.content.length > -1 || activeIndex !== props.index}
            content={selectedFilters.length ? `${selectedFilters.length} selected` : `By ${props.title}`}
            index={props.index}
            onClick={handleWrapperClick}
          />
          <Accordion.Content
            active={activeIndex !== props.index}
            content={accordianContent}
          />
        </Menu.Item>
      </Accordion>
    </div>
  )
}

export default Filter;