const defaultValues = {
  deviceA: 'Smartphone',
  deviceB: 'Non-Smartphone',
  environmentA: 'Social',
  environmentB: 'Non-Social',
}

const translateFilterKeyToTitle = {
  default_values: {
    deviceA: defaultValues.deviceA,
    deviceB: defaultValues.deviceB,
    environmentA: defaultValues.environmentA,
    environmentB: defaultValues.environmentB,
  },
  digital_market: {
    deviceA: defaultValues.deviceA,
    deviceB: defaultValues.deviceB,
    environmentA: defaultValues.environmentA,
    environmentB: defaultValues.environmentB,
  },
  'digital_market---paid_for_search': {
    deviceA: defaultValues.deviceA,
    deviceB: defaultValues.deviceB,
    environmentA: defaultValues.environmentA,
    environmentB: defaultValues.environmentB,
  },
  'digital_market---classified': {
    deviceA: defaultValues.deviceA,
    deviceB: defaultValues.deviceB,
    environmentA: 'Recruitment',
    environmentB: 'Recruitment(Other)',
  }
}

export default translateFilterKeyToTitle;