import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Chart, ArgumentAxis, ValueAxis, BarSeries, Title, Legend, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Stack, EventTracker, HoverState } from '@devexpress/dx-react-chart';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Export from './Export';
import ByCatch from '../General/ByCatch';
import * as d3Format from 'd3-format';

const Graph = props => {

  const [hover, setHover] = useState(undefined);
  const [targetItem, setTargetItem] = useState(undefined);

  const createSeriesStacks = data => {
    return data;
  }

  const Root = props => (
    <Legend.Root
      {...props}
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        margin: 'auto',
      }}
    />
  );

  const TextComponent = withStyles({
    title: {
      width: "100%",
      marginBottom: "10px",
      textAlign: 'left',
    }
  })(props => {
    const { text, classes } = props;
    const [mainText, subText] = text.split("\n");
    return (
      <div className={classes.title}>
        <Typography component="h3" variant="h5">
          {mainText}
        </Typography>
        <Typography variant="subtitle1">{subText}</Typography>
      </div>
    );
  });

  const changeHover = hover => {
    setHover(hover)
  }

  const changeTargetItem = targetItem => {
    setTargetItem(targetItem)
  }

  const formatTooltip = d3Format.format(",")

  const TooltipContent = tooltipInfo => {
    const { targetItem, text, ...restProps } = tooltipInfo;
    const seriesObject = props.chartInformation.chartData[targetItem.point]
    const seriesValue = Object.values(seriesObject)[targetItem.order];
    return (
      <div>
        <div>
          <Tooltip.Content
            {...restProps}
            // style={tooltipContentTitleStyle}
            text={`${targetItem.series} (${formatTooltip(seriesValue)})`}
          />
        </div>
      </div>
    );
  };

  const setColour = (itemName, index) => {
    const filterVariant = 4; // lower makes more variant
    const primaryStack = filterPercent => {
      return `rgba(255,0,0,${filterPercent})`
    }
    const secondaryStack = filterPercent => {
      return `rgba(0,0,255,${filterPercent})`
    }
    const checkSeries = (seriesIndex, itemName) => {
      const seriesIndexOfName =  props.chartInformation.stackSeries && props.chartInformation.stackSeries[seriesIndex] && props.chartInformation.stackSeries[seriesIndex].series.indexOf(itemName)
      return seriesIndexOfName;
    }
    if ( !itemName.includes('Total') ) {
      if ( checkSeries(0, itemName) > -1 ) {
        const position = checkSeries(0, itemName);
        const positionPercentage = (1 - (position / filterVariant));
        const fill = primaryStack(positionPercentage);
        const stroke = primaryStack(1)
        return {fill, stroke}
      } else if ( checkSeries(1, itemName) > -1 ){
        const position = checkSeries(1, itemName)
        const positionPercentage = (1 - (position / filterVariant));
        const fill = secondaryStack(positionPercentage);
        const stroke = secondaryStack(1)
        return {fill, stroke}
      }
    } else {
      const fill = 'rgb(255,255,255,0.3)';
      const stroke = 'black';
      return {fill, stroke}
    }
  }

  return props.chartInformation ? (
    <div className="iab-chart" id="iab-chart-wrapper">
      <Paper className="iab-chart__paper">
        <Chart
          data={props.chartInformation.chartData}
        >


          <Legend position="bottom" rootComponent={Root} />
          <Title text={
            `${props.chartInformation.title}\n between ${props.dateFrom} & ${props.dateTo}`
          }  textComponent={TextComponent}/>

          <ArgumentAxis />
          <ValueAxis />

          <Export />

          <EventTracker />

          {props.chartInformation.barItems.map((item, index) => {
            return (
              <BarSeries
                color={setColour(item, index).fill}
                name={item}
                valueField={item}
                key={item}
                argumentField="date"
                // style={{
                //   strokeWidth: '3',
                //   stroke: `${setColour(item, index).stroke}`,
                // }}
              />
            )
          })}

          <HoverState hover={hover} onHoverChange={changeHover} />

          <Tooltip
            targetItem={targetItem}
            onTargetItemChange={changeTargetItem}
            contentComponent={TooltipContent}
          />

          <Stack
            stacks={createSeriesStacks(props.chartInformation.stackSeries)}
          />

        </Chart>
        <ByCatch />
      </Paper>
    </div>
  ) : 'loading';
}

export default Graph;