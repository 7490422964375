import React  from 'react';

const ByCatch = props => {
  return (
    <div className="iab-by-catch">
      <span>Made by Catch</span>
      <div className="iab-by-catch__logo" />
    </div>
  )
}

export default ByCatch;