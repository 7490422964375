import checkFilterForValues from '../filters/checkFilterForValues';
import getSeriesKeyValue from './getSeriesKeyValue';
// sFilters is optional
const getFilterData = ( searchKeyObject, pFilters, sFilters) => {
  let series = {};
  let secondaryFilterName = '';
  let tempTotal = 0;

  const {key: searchKeyObjectName, filters: searchKeyObjectFilters } = getSeriesKeyValue(searchKeyObject)

  pFilters.map(filterKey => {

    const { name: filterName, value: filterValue, secondaryFilter } = checkFilterForValues(searchKeyObjectFilters, filterKey, sFilters) // sFilters is optional

    if (filterName && filterValue) {
      // add matched category to series
      series[`${searchKeyObjectName}, ${filterName}`] = filterValue;
      // add to total so we can remove it later
      tempTotal += filterValue;
      if (secondaryFilter) {
        secondaryFilterName = filterKey;
      }
    }
    return { series, tempTotal, secondaryFilterName }
  })
  return { series, tempTotal, secondaryFilterName }
}

export default getFilterData;