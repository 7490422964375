import React  from 'react';

const SelectDates = props => {
  return (
    <section className="iab-general">
      <div className="iab-general__calendar">
      </div>
      <div className="iab-general__heading">
        <h3>Please select date range to start using the stats tool</h3>
        <p>Lorem ipsum</p>
      </div>
    </section>
  )
}

export default SelectDates;