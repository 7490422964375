const checkFilterForValues = (apiReturnedFilterList, filterToCheck, optionalSecondaryFilters = []) => {
  if (!optionalSecondaryFilters.length) {
    const filterFind = apiReturnedFilterList.find(item => {
      return item.name.toLowerCase() === filterToCheck.toLowerCase()
    });
    if (filterFind){
      return filterFind;
    }
  } else {
    let filterFind = {}
    optionalSecondaryFilters.map(oSFilter => {
      apiReturnedFilterList.map(apiFilter => {
        const apiFilterName = apiFilter.name.split(" ").sort().join(" ").toLowerCase();
        const createdNameArray = [[filterToCheck], [oSFilter]];
        const createdNameSort = createdNameArray.sort().join(" ").toLowerCase();
        if ( apiFilterName === createdNameSort) {
          filterFind = {
            name: apiFilter.name,
            value: apiFilter.value,
            secondaryFilter: true,
          }
          return filterFind
        }
        return filterFind
      })
      return filterFind;
    })
    return filterFind
  }
  return {}
}

export default checkFilterForValues;