import domtoimage from "dom-to-image";
import JsPDF from "jspdf";

const iconButton = "exportIconButton";
const filter = node => node.id !== iconButton;

const exportToPdf = async chart => {
  const width = chart.offsetWidth;
  const height = chart.offsetHeight;
  try {
    const dataUrl = await domtoimage.toJpeg(chart, { filter });
    // @ts-ignore
    const doc = new JsPDF({
      orientation: "landscape",
      unit: "px",
      format: [width, height]
    });
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
    doc.addImage(dataUrl, "JPEG", 0, 0, pdfWidth, pdfHeight);
    doc.save("chart");
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("oops, something went wrong!", err);
  }
};

export default exportToPdf;