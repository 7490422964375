import React from "react";

function YearInput({ openPanel, selected, clear, selectedYear, selectedYearTo }) {
  const selectedClass = selected ? "input-wrapper-selected" : "";

  function clearHandler(e) {
    clear();
  }

  const getValue = () => {
    let value = '';
    if ( selectedYear === 0 || selectedYearTo === 0 ) {
      value = 'Select Years';
    } else {
      value = `${selectedYear} to ${selectedYearTo}`
    }
    return value;
  }

  return (
    <div className={`input-wrapper ${selectedClass}`}>
      <input
        className="year-input"
        value={getValue()}
        onClick={openPanel}
        placeholder="Select"
        readOnly
      />
      <i
        name="times"
        className="input-icon input-icon-calendar"
      />
      <i
        name="calendar"
        className="input-icon input-icon-close"
        onClick={clearHandler}
      />
    </div>
  );
}

export default YearInput;
