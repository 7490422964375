import getSearchedObjectData from '../chart/getSearchedObjectData';
import checkFilterForValues from './checkFilterForValues';

const checkDataForFilterValues = (yearAPIData, year, searchKey, filterType) => {
  const singleYear = getSearchedObjectData(yearAPIData, year, searchKey);

  if (!singleYear) {
    return;
  }

  const deviceTypes = ['Smartphone', 'Non-Smartphone'];
  const environmentTypes = ['Social', 'Non-Social', 'Recruitment'];

  const singleYearFilters = singleYear.filters;
  let filters = false;

  if (filterType === 'device') {
    filters = deviceTypes.some(filter => {
      const {name, value} = checkFilterForValues(singleYearFilters, filter);
      return name !== undefined && value > 0;
    })
    return filters;
  } else {
    filters = environmentTypes.some(filter => {
      const {name, value} = checkFilterForValues(singleYearFilters, filter);
      return name !== undefined && value > 0;
    })
    return filters;
  }
}

export default checkDataForFilterValues;