import React  from 'react';

const ToggleTotal = props => {
  return (
    <div className={`iab-toggle-total__wrapper iab-item-filter ${props.totalAvaliable ? 'iab-toggle-total__wrapper--inactive' : ''}`}>
    <p>Show Total?</p>
    <label className="iab-toggle-total">
      <input type="checkbox" onChange={props.handleToggleTotal} checked={props.isToggled}/>
      <span className="iab-toggle-total__slider"></span>
    </label>
    </div>
  )
}

export default ToggleTotal;