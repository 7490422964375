import React from "react";
import Button from "../Header/Button";
import CurrentYear from "../Header/CurrentYear";
import YearsList from "../Body/yearsList";

function PickerPanel({
  selectedYear,
  selectedYearTo,
  heldSelectedYear,
  heldSelectedYearTo,
  selectedYears,
  isOpen,
  increaseYear,
  decreaseYear,
  jumpForward,
  jumpBackward,
  chooseYear,
  chooseYearTo,
  top,
  left
}) {

  if (!isOpen) {
    return null;
  }

  let style = {
    top: top + "px",
    left: left + "px"
  };

  return (
    <div className="picker-panel popup-left" style={style}>
      <div className="header">
        <Button onClick={decreaseYear} direction="backward" title='yearFrom' />
        <CurrentYear year={`${selectedYear === 0 ? 'Select Year From' : `From: ${selectedYear}`}`} />
        <Button onClick={increaseYear} direction="forward" title='yearFrom' />
      </div>
      <div className="body">
        <YearsList
          title='YearFrom'
          chooseYear={chooseYear}
          selectedYear={selectedYear}
          selectedYears={selectedYears}
          heldSelectedYear={heldSelectedYear}
          heldSelectedYearTo={heldSelectedYearTo}/>
      </div>
      <div className="header">
        <Button onClick={decreaseYear} direction="backward" title='yearTo' />
        <CurrentYear year={`${selectedYearTo === 0 ? 'Select Year From' : `To: ${selectedYearTo}`}`} />
        <Button onClick={increaseYear} direction="forward" title='yearTo' />
      </div>
      <div className="body">
        <YearsList
          title='YearTo'
          chooseYear={chooseYearTo}
          selectedYear={selectedYearTo}
          selectedYears={selectedYears}
          heldSelectedYear={heldSelectedYear}
          heldSelectedYearTo={heldSelectedYearTo}/>
      </div>
    </div>
  );
}

export default PickerPanel;
