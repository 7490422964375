const iconButton = "exportIconButton";
const filter = node => node.id !== iconButton;

const exportToImage = async (chart, format, exportFunc) => {
  try {
    const dataUrl = await exportFunc(chart, { filter });
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.download = `chart.${format}`;
    link.href = dataUrl;
    link.click();
    link.remove();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("oops, something went wrong!", err);
  }
};

export default exportToImage;