import React from "react";

function Year({ year, selectedYear, highlighted, chooseYear }) {
  let selectedYearClass = year === selectedYear ? "selected-year" : "";

  function clickHandler(event) {
    chooseYear(year);
  }

  return (
    <div className="year-wrapper" year={year}>
      <button onClick={clickHandler} className={`year ${selectedYearClass} ${highlighted ? 'selected-year' : ''}`}>
        {year}
      </button>
    </div>
  );
}

export default Year;
