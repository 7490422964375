export const sidebarData = [
  {
    title: 'Digital Market Data',
    categories: [
      {
        title: 'Digital Market Overview',
        apiSearchTitle: 'digital_market---overall',
        categoryFilters: [
          {
            title: 'Paid For Search',
            searchObject: {
              title: 'paid_for_search',
              group: 'digital_market',
            }
          },{
            title: 'Classified',
            searchObject: {
              title: 'classified',
              group: 'digital_market',
            }
          }, {
            title: 'Other',
            searchObject: {
              title: 'other',
              group: 'digital_market',
            }
          },{
            title: 'Display',
            searchObject: {
              title: 'display',
              group: 'digital_market',
            }
          }
        ],
      }, {
        title: 'Paid For Search',
        apiSearchTitle: 'digital_market---paid_for_search',
      }, {
        title: 'Classified',
        apiSearchTitle: 'digital_market---classified',
      }, {
        title: 'Other Market Data',
        apiSearchTitle: 'digital_market---other',
      }, {
        title: 'Display',
        categories: [
          {
            title: 'Display Overview',
            apiSearchTitle: 'digital_market---display',
            categoryFilters: [
              {
                title: 'Sponsored Content',
                searchObject: {
                  title: 'sponsored_content',
                  group: 'display',
                }
              },{
                title: 'Banners / Standard Display',
                searchObject: {
                  title: 'banners_/_stanandard_display',
                  group: 'display',
                }
              },{
                title: 'Native',
                searchObject: {
                  title: 'native',
                  group: 'display',
                }
              },{
                title: 'Other Display',
                searchObject: {
                  title: 'other_display',
                  group: 'display',
                }
              },{
                title: 'Video',
                searchObject: {
                  title: 'video',
                  group: 'display',
                }
              }
            ],
          }, {
            title: 'Sponsored Content',
            apiSearchTitle: 'display---sponsored_content',
          }, {
            title: 'Banner',
            apiSearchTitle: 'display---banners_/_stanandard_display',
          }, {
            title: 'Native',
            apiSearchTitle: 'display---native',
          }, {
            title: 'Other Display',
            categories: [
              {
                title: 'Other Display Overview',
                apiSearchTitle: 'display---other_display',
                categoryFilters: [
                  {
                    title: 'Interruptive Formats',
                    searchObject: {
                      title: 'interruptive_formats',
                      group: 'other_display',
                    }
                  },{
                    title: 'Tenancies',
                    searchObject: {
                      title: 'tenancies',
                      group: 'other_display',
                    }
                  },{
                    title: 'Display Other',
                    searchObject: {
                      title: 'display_other',
                      group: 'other_display',
                    }
                  }
                ],
              }, {
                title: 'Interruptive Formats',
                apiSearchTitle: 'other_display---interruptive_formats',
              }, {
                title: 'Tenancies',
                apiSearchTitle: 'other_display---tenancies',
              }, {
                title: 'Display Other',
                apiSearchTitle: 'other_display---display_other',
              }
            ]
          }, {
            title: 'Video',
            categories: [
              {
                title: 'Video Overview',
                apiSearchTitle: 'display---video',
                categoryFilters: [
                  {
                    title: 'Outstream in feed',
                    searchObject: {
                      title: 'outstream_/_social_in-feed',
                      group: 'video',
                    }
                  },{
                    title: 'Other video',
                    searchObject: {
                      title: 'video_other',
                      group: 'video',
                    }
                  },{
                    title: 'Pre Mid Post',
                    searchObject: {
                      title: 'pre-mid-post_roll',
                      group: 'video',
                    }
                  }
                ],
              }, {
                title: 'Outstream In Feed',
                apiSearchTitle: 'video---outstream_/_social_in-feed',
              }, {
                title: 'Other Video',
                apiSearchTitle: 'video---video_other',
              }, {
                title: 'Pre Mid Post',
                categories: [
                  {
                    title: 'Pre Mid Post Overview',
                    apiSearchTitle: 'video---pre-mid-post_roll',
                    categoryFilters: [
                      {
                        title: 'Broadcaster',
                        searchObject: {
                          title: 'outstream_/_social_in-feed',
                          group: 'video',
                        }
                      },{
                        title: 'Non Broadcaster',
                        searchObject: {
                          title: 'video_other',
                          group: 'video',
                        }
                      },
                    ],
                  }, {
                    title: 'Broadcaster',
                    apiSearchTitle: 'pre-mid-post_roll---broadcaster_vod',
                  }, {
                    title: 'Non Broadcaster',
                    apiSearchTitle: 'pre-mid-post_roll---non-broadcaster_vod',
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  // }, {
  //   title: 'Another Data',
  //   categories: []
  }
]