import { sidebarData } from "../../components/Sidebar/sidebarData";

const getCategoryFilters = title => {
  const data = sidebarData[0].categories;
  // console.log(data)
  const found = data.find(category => {
    return category.apiSearchTitle === title && category.categoryFilters;
  })
  if (found) {
    return found.categoryFilters
  } else {
    return [];
  }
}

export default getCategoryFilters;