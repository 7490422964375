import React, { useState, useEffect, useRef } from 'react';
import Graph from './Graph';
import isEqual from 'lodash.isequal';

const GraphData = props => {
  const [chartInformation, setChartInformation] = useState({
    chartData: [],
    barItems: [],
    stackSeries: [],
    title: '',
  })

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { chartData, title } = props;
  const prevChartProps = usePrevious(chartData);

  useEffect(() => {

    const createChartSeries = chartData => {
      const series = [];
      chartData.forEach(item => {
        series.push(item.series);
      })
      return series;
    };

    const createChartStacks = chartData => {
      let stacks = [];
      chartData.map(item => {
        if ( item.stacks.length ) {
          let stackA = [];
          let stackB = [];
          let stacksTemp = []
          item.stacks.map((stack, index) => {
            return index === 0 ? stackA.push(mergeOnlyUnique(stack, stackA)) : stackB.push(mergeOnlyUnique(stack, stackB));
          })
          stacksTemp = [stackA, stackB];
          stacks = stacksTemp.map(stack => {
            return {series: stack}
          })
          return stacks;
        } else if ( item.series ) {
          stacks.push({series: mapObjectToArray(item.series)});
          return stacks;
        }
        return stacks;
      })
      return stacks;
    }

    const mergeOnlyUnique = (newItems, existingArray) => {
      let tempArray = existingArray;
      newItems.map(newItem => {
        if (tempArray.indexOf(newItem) <= 0) {
          tempArray.push(newItem);
          return tempArray;
        }
        return tempArray;
      })
    }

    // Takes an array or object and turns it into array items e.g. ['Hydro-electric', 'Oil', 'Natural gas', 'Coal', 'Nuclear']
    const createArrayOfItems = chartData => {
      let items = [];
      chartData.forEach(item => {
        const data = item.series;
        if (data.length) {
          items = mapArrayToIndividualArray(data)
        // if series is object
        } else {
          items = mapObjectToArray(data)
        }
      })
      return items;
    }

    // Turns array into individual unique items
    const mapArrayToIndividualArray = array => {
      const firstObject = array[0];
      return mapObjectToArray(firstObject)
    }

    // Turns object in array items
    const mapObjectToArray = object => {
      let arrayItems = [];
      let indexOfTotal = -1;
      let indexPosition = 0;

      const keys = Object.keys(object)
      for (const key of keys) {
        if ( !arrayItems.includes(key) && key !== 'date' && key !== 'filters' ) {
          // Ensure Total item is always at the end of the array list by setting index when we loop through
          if (key.includes('Total')) {
            indexOfTotal = indexPosition;
          }
          arrayItems.push(key);
          indexPosition++;
        }
      }
      // Ensure 'Total' item is always at the end of the array list
      if (indexOfTotal > -1) {
        arrayItems.push(arrayItems.splice(indexOfTotal, 1)[0]);
      }
      return arrayItems;
    }

    const createChartState = data => {
      const chartSeries = createChartSeries(data);
      const barItems = createArrayOfItems(data);
      const stackSeries = createChartStacks(data);
      setChartInformation({
        chartData: chartSeries,
        barItems: barItems,
        stackSeries: stackSeries,
        title: title,
      })
    }

    if (!isEqual(prevChartProps, chartData)) {
      createChartState(chartData)
    }

  }, [chartData, title, prevChartProps, setChartInformation])

  return chartInformation ? (
    <Graph
      chartInformation={chartInformation}
      dateFrom={props.dateFrom}
      dateTo={props.dateTo}
      primaryFilters={props.primaryFilters}
      secondaryFilters={props.secondaryFilters}
    />
  ) : 'load chart' ;
}

export default GraphData;