import React, { useState, useEffect } from "react";
// import Paper from "@material-ui/core/Paper";
import GraphData from './components/Chart/GraphData';
import DateSelector from './components/YearPicker';
import Filters from './components/Filter/Filters';
import Sidebar from './components/Sidebar/Sidebar';
import ToggleTotal from './components/ToggleTotal/ToggleTotal';
import { selectedSideBarItem }  from "./helpers/sidebar/getSetActiveItem";
import getCategoryFilters from './helpers/filters/getCategoryFilters';
// import Export from './components/Chart/Export';
import SelectDates from './components/General/SelectDates'
import GraphLoading from './components/General/GraphLoading';

import createSingleYearSeries from './helpers/chart/createSingleYearSeries';
import checkDataForFilterValues from './helpers/filters/checkDataForFilterValues';
import translateDeviceTitlesFromKey from './helpers/translators/translateDeviceTitlesFromKey';
import translateEnvironmentTitlesFromKey from './helpers/translators/translateEnvironmentTitlesFromKey';

import getDataForYears from './api/getDataForYears';

// const rootContainerId = "widget-container";

function App() {
  const [retreivedApiStatus, setRetreivedApiStatus] = useState()
  const [retreivedApiData, setRetreivedApiData] = useState([])
  const [chartData, setChartData] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [yearsSelected, setYearsSelected] = useState(false);
  const [showTotal, setShowTotal] = useState(false);

  const [searchTitle, setSearchTitle] = useState(selectedSideBarItem.title)
  const [apiSearchTitle, setApiSearchTitle] = useState(selectedSideBarItem.apiSearchTitle)

  // Filters to display
  const [categoryListOptions, setCategoryListOptions] = useState(selectedSideBarItem.categoryFilters);
  const [environmentListOptions, setEnvironmentListOptions] = useState([]);
  const [deviceListOptions, setDeviceListOptions] = useState([]);

  // Filters user selects
  const [primaryFilters, setPrimaryFilters] = useState({
    filterName: '',
    filters: [],
  });
  const [secondaryFilters, setSecondaryFilters] = useState({
    filterName: '',
    filters: [],
  });

  // Graph title
  const handleUpdateTitle = input => {
    setSearchTitle(input);
  }

  // Api name / key to search for
  const handleUpdateApiSearchTitle = input => {
    setApiSearchTitle(input);
  }

  const handleToggleTotal = () => {
    setShowTotal(!showTotal);
  }

  // Set categories if on overview
  const handleUpdateCategories = array => {
    setCategoryListOptions(array);
  }

  // Set year from
  const setFromYear = input => {
    setDateFrom(input);
  }

  // set year to
  const setToYear = input => {
    setDateTo(input);
  }

  const handleYearsSelected = bool => {
    setYearsSelected(bool);
  }

  const handleSetPrimaryFilters = (title, array) => {
    setPrimaryFilters({
      filterName: title,
      filters: array,
    });
    if (!array.length){
      setShowTotal(false);
    }
  }

  const handleSetSecondaryFilters = (title, array) => {
    setSecondaryFilters({
      filterName: title,
      filters: array,
    });
  }

  useEffect(() => {

    const getAPIData = searchTitle => {

      let retreivedApiDataArray = [];
      Object.entries(retreivedApiData).map(([key, val]) => {
        const yearObject = {date: key, data: val};
        retreivedApiDataArray.push(yearObject)

        return retreivedApiDataArray;
      });

      let graphData = [];
      let environmentFiltersToShow = false;
      let deviceFiltersToShow = false;

      retreivedApiDataArray.map(item => {
        const { date } = item;
        const thisYearsData = createSingleYearSeries(retreivedApiData, date, searchTitle, primaryFilters, secondaryFilters, showTotal);
        graphData.push(thisYearsData);

        if ( environmentFiltersToShow || checkDataForFilterValues(retreivedApiData, date, searchTitle, 'environment') === true ) {
          environmentFiltersToShow = true;
        }

        if ( deviceFiltersToShow || checkDataForFilterValues(retreivedApiData, date, searchTitle, 'device') === true ) {
          deviceFiltersToShow = true;
        }

        return graphData
      });

      environmentFiltersToShow ? setEnvironmentListOptions(translateEnvironmentTitlesFromKey(searchTitle)) : setEnvironmentListOptions([])
      deviceFiltersToShow ? setDeviceListOptions(translateDeviceTitlesFromKey(searchTitle)) : setDeviceListOptions([])

      return graphData;
    }

    if (retreivedApiData !== []) {
      // set chart data
      setChartData(getAPIData(apiSearchTitle));
      setCategoryListOptions(getCategoryFilters(apiSearchTitle))
    }
  },
    [
      retreivedApiData,
      retreivedApiStatus,
      apiSearchTitle,
      primaryFilters,
      secondaryFilters,
      showTotal,
    ]
  );

  useEffect(() => {

    const getAPIData = (dateFrom, DateTo) => {

      const apiData = getDataForYears(dateFrom, dateTo);
      apiData.then(function (response) {
        // handle success
        const {data, status} = response;
        // setRetreivedApiData(data.data);
        // setRetreivedApiStatus(status);
        setTimeout(function(){
          setRetreivedApiData(data.data);
          setRetreivedApiStatus(status);
        }, 1500);
      })
      .catch(function (error) {
        // handle error
        const { status } = error;
        setRetreivedApiStatus(status);
      })

    }

    if (dateFrom && dateTo && yearsSelected) {
      getAPIData(dateFrom, dateTo);
    }
  },
    [
      dateFrom,
      dateTo,
      yearsSelected
    ]
  );

  return (
    <div className="iab-app-structure">
      <aside className="iab-app-structure__sidebar">
        <React.Fragment>
          <div className="iab-sidebar-selector__heading">
            <p>Explore</p>
          </div>
          <Sidebar
            handleUpdateTitle={(input) => handleUpdateTitle(input)}
            handleUpdateApiSearchTitle={(input) => handleUpdateApiSearchTitle(input)}
            handleUpdateCategories={(array) => handleUpdateCategories(array)}
          />
        </React.Fragment>
      </aside>
      <main className="iab-app-structure__main">
        <header className="iab-app-structure__filters">
            <React.Fragment>
              <DateSelector
                setFromYear={setFromYear}
                setToYear={setToYear}
                yearsSelected={handleYearsSelected}
              />
              <Filters
                // chartData
                chartData={chartData}

                // Filters to display
                categoryListOptions={categoryListOptions}
                environmentListOptions={environmentListOptions}
                deviceListOptions={deviceListOptions}

                // Filters user selects
                primaryFilters={primaryFilters}
                secondaryFilters={secondaryFilters}
                handleSetPrimaryFilters={handleSetPrimaryFilters}
                handleSetSecondaryFilters={handleSetSecondaryFilters}
              />
              <ToggleTotal
                totalAvaliable={!primaryFilters.filters.length}
                handleToggleTotal={handleToggleTotal}
                isToggled={showTotal}
              />
            </React.Fragment>
        </header>
        <section className="iab-app-structure__view">
          {yearsSelected ? (
            chartData.length ? (
              <GraphData
                chartData={chartData}
                dateTo={dateTo}
                dateFrom={dateFrom}
                title={searchTitle}
                primaryFilters={primaryFilters}
                secondaryFilters={secondaryFilters} />
            ) : <GraphLoading />
          ) : <SelectDates /> }

        </section>
      </main>
    </div>
  );
}

export default App;